.actions-panel {
  animation: delayRaisePanel 0ms forwards;
  animation-delay: 150ms;
}

@keyframes delayRaisePanel {
  0% {
    z-index: auto;
  }
  100% {
    z-index: 2;
  }
}
