.item {
  position: relative;
  margin-bottom: 0;
  line-height: 1;

  &:after {
    position: absolute;
    top: 15%;
    right: -0.5px;
    display: block;
    width: 1px;
    height: 70%;
    transition: opacity 200ms ease-out;
    content: "";
  }

  &:last-of-type {
    &:after {
      display: none;
    }
  }
}

.button {
  position: relative;
  margin: 0;
  line-height: 1;
  background: transparent;
  border: none;
  outline: none;

  &:hover,
  &:focus {
    cursor: pointer;
  }
}
