.toolbar {
  opacity: 0;
  z-index: 1;
  margin-top: 2rem;
  padding: 0.2rem;
  display: flex;
  align-items: center;
  background: rgba(var(--color-surface-over), 0.92);
  backdrop-filter: blur(8px);
  box-shadow:
    rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px,
    rgba(15, 15, 15, 0.2) 0px 9px 24px;
  border-radius: 6px;
  pointer-events: auto;
}

.Aitoolbar {
  opacity: 0;
  z-index: 10;
  padding: 0.18rem;
  display: flex;
  flex-direction: column;
  min-width: 180px;
  max-width: 100%;
  height: 264px;
  cursor: pointer;
  overflow: auto;
  max-height: min(40vh, 320px);
  background: rgba(var(--color-surface-over), 0.92);
  backdrop-filter: blur(8px);
  margin-top: 5rem;
  box-shadow: var(--shadow-over);
  border-radius: 4px;
  pointer-events: auto;
}

.replaceText {
  opacity: 0;
  z-index: 10;
  padding: 0.18rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-width: 180px;
  max-width: 100%;
  width: 220px;
  overflow: auto;
  max-height: min(40vh, 320px);
  background: rgba(var(--color-surface-over), 0.92);
  backdrop-filter: blur(8px);
  margin-top: 5rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  pointer-events: auto;
}

.changeTonDropDown {
  opacity: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  width: 220px;
  cursor: pointer;
  margin-top: 8rem;
  padding-bottom: 0.5rem;
  max-width: 100%;
  max-height: min(40vh, 320px);
  background: rgba(var(--color-surface-over), 0.92);
  backdrop-filter: blur(8px);
  box-shadow: var(--shadow-over);
  border-radius: 6px;
  pointer-events: auto;
}

.menuItem:hover {
  background-color: rgb(238, 238, 238);
  border-radius: 5px;
}

.activeClass {
  background-color: rgb(238, 238, 238);
  border-radius: 5px;
}

.tag_selector {
  margin-left: 0;
}

.separator {
  width: 1px;
  height: 1rem;
  margin: 0 0.25rem;
  background: rgba(var(--color-foreground), 0.12);
}

.group {
  display: flex;
  align-items: center;
  gap: 2px;
}

.loadingOverlay {
  background: transparent;
  height: 100%;
  z-index: 5;
}

.textAreaContainer {
  width: 100%;
}

.aiTextArea {
  user-select: none;
  outline: none;
  /* width: 678px; */
  text-align: start;
  overflow-y: auto;
  resize: none;
}
