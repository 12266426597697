.hovered-extracted-requirement {
  background-color: rgba(41, 40, 40, 0.2) !important;
  border-color: rgb(107 114 128) !important;
}

.hovered-selected-requirement {
  background-color: rgba(42, 70, 171, 0.4) !important;
}

.highlighted-assigned-requirement {
  background-color: rgba(14, 120, 8, 0.4) !important;
  outline-width: 2px !important;
  outline-style: solid;
  outline-offset: -1px;
}

.highlighted-extracted-requirement {
  background-color: rgba(41, 40, 40, 0.3) !important;
  outline-width: 2px !important;
  outline-style: solid;
  outline-offset: -1px;
}

.highlighted-selected-requirement {
  background-color: rgba(42, 70, 171, 0.4) !important;
  outline-width: 2px !important;
  outline-style: solid;
  outline-offset: -1px;
}

.highlighted-dragged-selected-requirement {
  background-color: rgba(255, 221, 0, 0.2) !important;
  outline-color: rgb(255, 208, 0) !important;
  outline-width: 2px !important;
  outline-style: solid;
  outline-offset: -1px;
}

.selection-area {
  background: rgba(46, 115, 252, 0.11);
  border: 2px solid rgba(98, 155, 255, 0.81);
  border-radius: 0.1em;
}

.ask-ai-button {
  transition: background-position 0.3s ease-in-out;
  background-image: radial-gradient(50% 50%, #646464 0%, #1e1e1e 100%);
}

.ask-ai-button:hover {
  background-image: radial-gradient(50% 90%, #646464 0%, #1e1e1e 100%);
}
