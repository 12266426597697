.editorHeader {
  top: 0;
  left: 0;
  right: 0;
  flex: 0 0;
  display: flex;
  justify-content: space-between;
  align-items: start;
  background: var(--color-surface-elevated);
  border-bottom: 1px solid var(--color-border);
  padding: var(--space-3);
  z-index: var(--z-above);
}

.editorPanel {
  flex: 1 1;
  overflow-y: scroll;
}

.editor {
  border-radius: inherit;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.editor:focus {
  outline: none;
}

.editorContainer {
  position: relative;
  min-height: 100%;
  width: 100%;
  max-width: 900px;
  margin: var(--space-8) auto;
  border: 1px solid var(--color-border);
  background: var(--color-surface-elevated);
}

.placeholder {
  pointer-events: none;
  position: absolute;
  inset: 0;
  opacity: 0.3;
  padding: 0 1em;
}

.bubbleMenuWrapper {
  display: flex;
  align-items: center;
  background: #262626;
  border-radius: 6px;
  border: 1px solid #171717;
  box-shadow:
    0px 10px 15px 0px #00000040,
    inset 0px 3px 15px 0px #52525230;
}

.bubbleDivider {
  width: 1px;
  align-self: stretch;
  background: #444444;
  margin: 0 2px;
}

.editor {
  position: relative;
  cursor: text;
  overflow: auto;
  overflow-x: hidden;
}

.editorWrapper {
  position: relative;
  cursor: text;
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
}

.sortable {
  outline: none;
}

.prose {
  /* padding: 2rem 0 0; */
  height: 100%;
}

.block {
  position: relative;
  display: flex;
  flex-direction: column;
}

.placeholder {
  position: absolute;
  color: rgba(var(--color-foreground), 0.3);
  pointer-events: none;
  user-select: none;
  z-index: -1;
  top: 0;
}

.inline_actions {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  padding-right: 0.75rem;
  transform: translateX(-100%);
}

.block:hover .inline_actions,
.block_selected .inline_actions {
  opacity: 0.5;
}

.block:hover .inline_actions:hover,
.block_selected .inline_actions:hover {
  opacity: 1;
}

.avatars {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0.25rem;
  left: 0;
  padding-right: 1rem;
  transform: translateX(-100%);
}

.avatars > * {
  margin-left: 0;
}

.block:hover .avatars,
.block_selected .avatars {
  opacity: 0;
  pointer-events: none;
}
.editorHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container {
  width: 100%;
  margin: 0 auto;
}
