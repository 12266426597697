.loading {
  display: flex;
  place-content: center;
  place-items: center;
  background-color: rgb(var(--color-surface));
  padding: 20px 0;
}

.loading img {
  width: 64px;
  height: 64px;
  opacity: 0.2;
}
