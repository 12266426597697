.selection {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.selection_border {
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  opacity: 0.2;
}

.selection_name {
  position: absolute;
  top: -28px;
  right: 0;
  height: 20px;
  padding: 0 6px;
  border-radius: 3px;
  color: white;
  font-size: 12px;
  line-height: 20px;
}
