.cursor {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
}

.nameWrapper,
.avatarWrapper {
  position: relative;
}

.cursorSvg {
  position: absolute;
  top: 0;
  left: 0;
}

.namePill {
  position: absolute;
  overflow: hidden;
  top: 1rem;
  left: 1rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  white-space: nowrap;
  border-radius: 0.5rem;
}

.namePillName {
  z-index: 10;
  position: relative;
}

.avatar {
  display: block;
  position: absolute;
  top: 16px;
  left: 16px;
  overflow: hidden;
  border-radius: 9999px;
  outline-width: 2px;
  outline-style: solid;
  outline-offset: 2px;
}
