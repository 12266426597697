.select_trigger {
  position: relative;
  height: 1.75rem;
  color: rgba(var(--color-foreground), 1);
  border-radius: 6px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  font-size: 0.875em;
  padding: 0 0.25rem 0 0.5rem;
  background: none;
  margin: 0;
  user-select: none;
}

.select_trigger:focus {
  outline: none;
}

.select_trigger:disabled {
  opacity: 0.3;
  pointer-events: none;
}

.select_trigger_chevron {
  margin-left: 2px;
  opacity: 0.4;
}

.select_trigger:hover,
.select_trigger:focus {
  color: rgba(var(--color-foreground), 1);
  background: rgba(var(--color-foreground), 0.1);
}

.select_trigger:hover .select_trigger_chevron,
.select_trigger:focus .select_trigger_chevron {
  opacity: 0.6;
}

.select_content {
  background: rgba(var(--color-surface-over), 0.92);
  backdrop-filter: blur(8px);
  box-shadow: var(--shadow-over);
  border-radius: 8px;
  padding: 4px;
  z-index: 20;
  user-select: none;
}

.select_item {
  height: 1.75rem;
  border-radius: 6px;
  padding: 0 1rem 0 1.75rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  cursor: default;
}

.select_item:focus {
  outline: none;
  background: rgb(var(--color-primary));
  color: #fff;
}

.select_item_indicator {
  position: absolute;
  left: 0.5rem;
  display: flex;
  align-items: center;
  width: 1rem;
}
