@keyframes up {
  0% {
    opacity: 0;
    transform: translateY(var(--space-3));
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes down {
  0% {
    opacity: 0;
    transform: translateY(calc(-1 * var(--space-3)));
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.tooltip {
  animation: 0.15s ease-in-out;
}

.tooltip[data-side="top"] {
  animation-name: up;
}

.tooltip[data-side="bottom"] {
  animation-name: down;
}
