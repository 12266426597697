.trigger {
  display: flex;
  height: var(--space-13);
  padding: 0 var(--space-4);
  border-radius: var(--radius-sm);
  font-size: var(--size-sm);
  gap: var(--space-4);
  place-items: center;
  background: var(--color-surface);
  color: #1e1e1e;
  transition: var(--transition);
  transition-property: background, color, opacity;
  outline: none;
}

.triggerSubtle {
  background: none;
}

.trigger:hover,
.trigger:focus-visible {
  background: var(--color-surface-hover);
  color: var(--color-text);
}

.triggerSubtle:hover,
.triggerSubtle:focus-visible {
  background: var(--color-surface);
  color: var(--color-text);
}

.triggerIcon {
  flex: none;
  margin-left: auto;
}

.trigger[data-placeholder] {
  color: var(--color-text-lightest);
  font-weight: 400;
}

.trigger[data-disabled] {
  cursor: default;
  opacity: var(--opacity-disabled);
}

.select {
  z-index: var(--z-above);
  padding: var(--space-3);
  border: 1px solid var(--color-border);
  background: var(--color-surface-elevated);
  border-radius: var(--radius);
  box-shadow: var(--shadow-xl);
  overflow-y: auto;
  max-height: 280px;
}

.item {
  display: flex;
  padding: var(--space-3) var(--space-5);
  border-radius: var(--radius-sm);
  color: var(--color-text-light);
  cursor: pointer;
  font-size: var(--size-sm);
  font-weight: 500;
  outline: none;
  place-items: center;
  user-select: none;
}

.itemInfo {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
}

.itemDescription {
  font-size: var(--size-xs);
  font-weight: 400;
  opacity: 0.6;
}

.item[data-highlighted] {
  background: var(--color-surface);
  color: var(--color-text);
}

.item[data-disabled] {
  cursor: default;
  opacity: var(--opacity-disabled);
}

.itemIndicator {
  width: 16px;
  height: 16px;
  flex: none;
  margin-right: var(--space-4);
  margin-left: calc(-1 * var(--space-1));
}
