.web-loader {
  background-color: white;
}

.fixed-loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.104);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.loader {
  border: 4px solid #fff;
  border-top: 4px solid #282b36;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  -webkit-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
  -webkit-filter: drop-shadow(0px 0px 10px #bfbfbf);
  filter: drop-shadow(0px 0px 10px #bfbfbf);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}

.loader-wrap {
  margin: 0 auto;
  overflow: hidden;
  background-color: #fff;
  /* border: 2px solid #fff; */
  position: relative;
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  -webkit-filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.212));
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.212));
}

.loader-logo {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
}

.loader-logo img {
  -o-object-fit: contain;
  object-fit: contain;
  width: auto;
  max-height: 32px;
  max-width: 32px;
  margin: auto;
}

.sm_spinner .loader {
  border-width: 3px;
}

.sm_spinner .loader-wrap {
  background-color: transparent;
}

.sm_spinner .loader-wrap {
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
}

.sm_spinner .loader,
.sm_spinner .loader-logo {
  width: 31px;
  min-width: 31px;
  height: 31px;
  min-height: 31px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .loader-wrap {
    width: 58px;
    height: 58px;
  }
  .loader-logo {
    width: 48px;
    height: 48px;
  }
  .loader {
    border-width: 3px;
    width: 48px;
    height: 48px;
    min-width: 56px;
    min-height: 56px;
  }
  .loader-logo img {
    max-width: 75%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    -o-object-fit: contain;
    object-fit: contain;
  }
  .sm_spinner .loader-wrap {
    width: 25px;
    min-width: 25px;
    height: 25px;
    min-height: 25px;
  }
  .sm_spinner .loader,
  .sm_spinner .loader-logo {
    width: 21px;
    min-width: 21px;
    min-height: 21px;
    height: 21px;
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-circle {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid;
  animation: load 1s infinite linear;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
