.toolbar {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  padding: 0 var(--space-2);
}

.toolbarSection {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 var(--space-2);
}
.avatarAndExpand {
  display: flex;
  align-items: center;
  gap: var(--space-2);
}

.toolbarSection:not(:first-child)::before {
  content: "";
  position: absolute;
  width: 1px;
  height: var(--space-10);
  background: var(--color-border);
  left: 0;
}

.toolbarButton svg {
  height: 20px;
  width: 20px;
  color: #1e1e1e;
}

.toolbarSeparator {
  width: 1px;
  height: var(--space-10);
  background: var(--color-border);
  margin: 0 var(--space-2);
}

.toolbarPopover {
  display: flex;
  height: auto;
  min-width: 160px;
  flex-direction: column;
  padding: var(--space-4);
  box-shadow: var(--shadow-xl);
}

.toolbarPopoverLabel {
  font-size: var(--size-sm);
  font-weight: 500;
  padding: var(--space-2) var(--space-1) var(--space-4);
}

.toolbarPopoverBar {
  display: flex;
  gap: var(--space-3);
}

.toolbarPopoverInput {
  width: 200px;
}

.toolbarPopoverButton {
  white-space: nowrap;
}

.headingsSelect {
  width: 120px;
}

/* Ask Ai toggle */
.formToolbarPopOver {
  display: flex;
  height: auto;
  min-width: 160px;
  flex-direction: column;
  /* box-shadow: var(--shadow-xl); */
}
.toolbarAskAiButton div {
  display: flex;
  align-items: center;
  gap: var(--space-2);
}

.typeWriter {
  /* background: #ffffffff; */
  border-radius: 0;
  border: none;
  /* border-bottom: 1px solid #ededed; */
  font-size: 14px;
  line-height: 1.5;
  padding: 15px 15px 0px 15px;
  min-height: 50px;
  /* border-radius: 4px; */
  resize: none;
  transition: border-color 150ms ease;
  width: 100%;
  overflow: auto;
}
.typeWriterDiv {
  background: #ffffffff;
}
.toolbarAskAiPopoverInput {
  /* background: #ffffffff; */
  border-radius: 0;
  border: none;
  /* border-bottom: 1px solid #ededed; */
  font-size: 14px;
  line-height: 1.5;
  padding: 15px 30px;
  min-height: 50px;
  /* border-radius: 4px; */
  resize: none;
  transition: border-color 150ms ease;
  width: 100%;
  overflow: hidden;
}
.toolbarAskAiPopoverInput:hover {
  border-color: #c6c6c6;
}

.toolbarAskAiPopoverInput:focus {
  outline: none;
  border-color: #c6c6c6;
}

.toolbarAskAiPopoverInput::placeholder {
  color: rgba(var(--color-foreground), 0.5);
}

.toolbarAskAiPopoverBar {
  display: flex;
  font-family: Helvetica, Arial, sans-serif;
  justify-content: center;
  flex-direction: column;
  max-width: 40ch;
}

.replaceText {
  opacity: 0;
  z-index: 10;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: min(40vh, 320px);
  backdrop-filter: blur(8px);
  margin-top: 5rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  pointer-events: auto;
}

.mainDropDown {
  background: #ffffffff;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  max-width: 100%;
  padding: 0.18rem;
  min-height: 50px;
  border-radius: 4px;
  transition: border-color 150ms ease;
}

.scrollableToolbar {
  overflow-y: hidden;
}

/* Firefox-only scrollbar styling */
@-moz-document url-prefix() {
  .scrollableToolbar {
    scrollbar-width: thin;
  }
}

div.scrollableToolbar::-webkit-scrollbar {
  height: 3px;
}
