.avatars {
  display: flex;
  padding: 0 0.75rem;
}

.avatar {
  display: flex;
  place-content: center;
  position: relative;
  border: 4px solid #fff;
  border-radius: 9999px;
  width: 42px;
  /* margin: 0 1.25rem; */
  height: 42px;
  background-color: #9ca3af;
  margin-left: 0rem;
}

.avatar:before {
  content: attr(data-tooltip);
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  transition: opacity 0.15s ease;
  padding: 5px 10px;
  color: white;
  font-size: 0.75rem;
  border-radius: 8px;
  margin-top: 10px;
  z-index: 1;
  background: black;
  white-space: nowrap;
}

/* Hide tooltip if no tooltip is unset */
.avatar[data-tooltip]:not([data-tooltip=""]):hover:before {
  opacity: 1;
}

.avatar_picture {
  border-radius: 9999px;
}
