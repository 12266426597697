.button {
  border-radius: 6px;
  border: none;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  min-width: 1.75rem;
  font-size: 1em;
  margin: 0;
  outline: none;
  font-weight: 500;
  user-select: none;
  min-width: fit-content;
}

.button_square {
  padding: 0;
}

.button_ghost {
  transition-duration: 100ms;
  color: rgba(var(--color-foreground), 0.8);
  background: none;
}

.button_ghost:hover,
.button_ghost:focus {
  color: rgba(var(--color-foreground), 1);
  background: rgba(var(--color-foreground), 0.1);
}

.button_secondary {
  color: rgba(var(--color-foreground), 0.9);
  background: rgba(var(--color-foreground), 0.06);
}

.button_secondary:hover,
.button_secondary:focus {
  color: rgba(var(--color-foreground), 1);
  background: rgba(var(--color-foreground), 0.1);
}

.button_primary {
  color: rgba(255, 255, 255, 1);
  background: rgba(var(--color-primary), 0.96);
  font-weight: 500;
}

.button_primary:hover,
.button_primary:focus {
  background: rgba(var(--color-primary), 1);
}
