/* Placeholder in first line */
p.tiptap-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
/* Placeholder in first line */
div.proposal-editor-empty.tiptap-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.tiptap-paragraph {
  margin: var(--space-8) 0;
}

.markdown-paragraph {
  padding-top: 0.25rem;
}

.tiptap-hr {
  margin: var(--space-14) 0;
  border-bottom: 1px solid var(--color-border);
}

/* Heading styles below */
h1.tiptap-heading {
  font-size: var(--size-3xl);
  font-weight: bold;
  line-height: 1em;
  margin-top: var(--space-12);
  margin-bottom: var(--space-8);
}

h2.tiptap-heading {
  font-size: var(--size-2xl);
  font-weight: bold;
  line-height: 1em;
  margin-top: var(--space-12);
  margin-bottom: var(--space-8);
}

h3.tiptap-heading {
  font-size: var(--size-xl);
  font-weight: bold;
  margin-top: var(--space-12);
  margin-bottom: var(--space-8);
}

h4.tiptap-heading {
  font-size: var(--size-lg);
  font-weight: bold;
  line-height: 1em;
  margin-top: var(--space-12);
  margin-bottom: var(--space-8);
}

h5.tiptap-heading {
  font-size: var(--size-md);
  font-weight: bold;
  line-height: 1em;
  margin-top: var(--space-12);
  margin-bottom: var(--space-8);
}

h1.tiptap-heading:first-child,
h2.tiptap-heading:first-child,
h3.tiptap-heading:first-child,
h4.tiptap-heading:first-child,
h5.tiptap-heading:first-child {
  margin-top: 0;
}

h1.tiptap-heading + h2.tiptap-heading,
h1.tiptap-heading + h3.tiptap-heading,
h2.tiptap-heading + h1.tiptap-heading,
h2.tiptap-heading + h3.tiptap-heading,
h3.tiptap-heading + h1.tiptap-heading,
h3.tiptap-heading + h2.tiptap-heading,
h3.tiptap-heading + h4.tiptap-heading,
h3.tiptap-heading + h5.tiptap-heading,
h4.tiptap-heading + h3.tiptap-heading,
h4.tiptap-heading + h5.tiptap-heading,
h5.tiptap-heading + h3.tiptap-heading,
h5.tiptap-heading + h4.tiptap-heading,
h1.tiptap-heading + h4.tiptap-heading,
h1.tiptap-heading + h5.tiptap-heading,
h2.tiptap-heading + h4.tiptap-heading,
h2.tiptap-heading + h5.tiptap-heading,
h4.tiptap-heading + h1.tiptap-heading,
h4.tiptap-heading + h2.tiptap-heading,
h5.tiptap-heading + h1.tiptap-heading,
h5.tiptap-heading + h2.tiptap-heading {
  margin-top: 0;
}

/* Advanced inline styles below */
.tiptap-code {
  font-size: 1.2em;
  line-height: 1em;
  background: var(--color-surface-hover);
  border-radius: var(--radius-xs);
  font-weight: 400;
  padding: 0 var(--space-2);
}

h1 .tiptap-code,
h2 .tiptap-code,
h3 .tiptap-code {
  font-size: 1em;
}

.tiptap-highlight {
  background: rgb(var(--color-primary), 0.1);
  border-color: rgba(var(--color-primary), 0.6);
  outline: none;
  box-shadow: 0 0 0 3px rgba(var(--color-primary), var(--opacity-surface-selection));
}

.text-underline {
  text-decoration: underline;
}

.tiptap-link {
  color: var(--color-accent);
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}

/* Block styles below */
.tiptap-blockquote {
  margin-left: var(--space-8);
  padding-left: var(--space-8);
  display: block;
  border-left: var(--space-2) solid var(--color-border-contrasted);
}

.tiptap-list-item p {
  margin: var(--space-2) 0;
}

.tiptap-list-item {
  margin-left: var(--space-12);
  list-style: disc;
}

.tiptap-list-item .tiptap-list-item {
  margin-left: var(--space-8);
  list-style: circle;
}

.tiptap-list-item .tiptap-list-item .tiptap-list-item {
  margin-left: var(--space-8);
  list-style: square;
}

/* Ensure deeper levels have the same depth and style as the third level */
.tiptap-list-item .tiptap-list-item .tiptap-list-item .tiptap-list-item {
  margin-left: 0;
  list-style: square;
}

.tiptap-ordered-list .tiptap-list-item {
  list-style: decimal;
}

.tiptap-ordered-list .tiptap-ordered-list .tiptap-ordered-list {
  counter-reset: list-item;
}

.tiptap-ordered-list .tiptap-ordered-list .tiptap-ordered-list .tiptap-ordered-list {
  counter-reset: none;
  counter-increment: list-item 0; /* Increase counter by 0 */
}

/* Ensure deeper levels have the same depth and style as the third level */
.tiptap-ordered-list .tiptap-list-item .tiptap-list-item .tiptap-list-item,
.tiptap-ordered-list .tiptap-list-item .tiptap-list-item .tiptap-list-item .tiptap-list-item {
  list-style: none;
  counter-increment: list-item;
  position: relative;
}

/* Ensure deeper levels have the same depth and style as the third level */
.tiptap-ordered-list .tiptap-list-item .tiptap-list-item .tiptap-list-item::before {
  content: counter(list-item) ". ";
  position: absolute;
  left: -1.5em;
}

.tiptap-task-list {
  list-style: none;
  padding: 0;
}

.tiptap-task-list p {
  margin: 0;
}
/* Styling for task item in /components/TextEditor/CustomTaskItem */

/* Media blocks below */
.tiptap-code-block {
  font-size: var(--size-sm);
  line-height: 1.6;
  white-space: pre;
  background: rgb(var(--color-gray-850));
  color: rgb(var(--color-gray-100));
  padding: var(--space-8);
  border-radius: var(--radius-xs);
  margin: var(--space-8) 0;
}

.tiptap-image,
.tiptap-youtube {
  margin: var(--space-8) 0;
  max-height: 600px;
  max-width: 100%;
  object-fit: contain;
}

.tiptap-image:hover {
  resize: both; /* You can change 'both' to 'horizontal', 'vertical', or 'none' based on your preference */
  overflow: auto; /* Add overflow to show scrollbars when resizing */
  cursor: grab; /* Add a grabbing cursor to indicate that the image is draggable */
  border: 2px solid #007bff; /* Optional: Add a border to indicate the resizable area */
  max-width: 100%; /* Ensure the image doesn't exceed its container width */
}

.tiptap-image.ProseMirror-selectednode,
.tiptap-youtube.ProseMirror-selectednode {
  outline: 1.5px solid var(--color-accent);
}

.tiptap-youtube {
  border: 0;
}

/* Give a remote user a caret */
.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  font-style: normal;
  font-weight: 600;
  left: 50%; /* Adjusted from left: -1px; */
  transform: translateX(-50%);
  line-height: normal;
  position: absolute;
  user-select: none;
  white-space: nowrap;
  font-size: 14px;
  color: #fff;
  top: -1.4em;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  padding: 2px 6px;
  pointer-events: none;
}

/* Comments */

.comment-hidden {
  background-color: transparent;
  border-bottom: none;
}

.comment-idle,
.comment-resolved.comment-idle {
  background-color: rgba(254, 209, 25, 0.3);
  border-bottom: 2px solid rgb(254, 209, 25);
  cursor: pointer;
}

.comment-active,
.comment-draft {
  background-color: rgba(212, 166, 13, 0.5) !important;
  border-bottom: 2px solid rgb(254, 209, 25);
}

.comment-resolved {
  background-color: transparent;
}

.comment-idle,
.comment-active,
.comment-draft,
.comment-resolved,
.comment-hidden {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
}

.comment-overlap-2,
.comment-resolved.comment-overlap-2 {
  background-color: rgba(254, 209, 25, 0.45);
}

.comment-overlap-3,
.comment-resolved.comment-overlap-3 {
  background-color: rgba(254, 209, 25, 0.6);
}

.comment-overlap-4,
.comment-resolved.comment-overlap-4 {
  background-color: rgba(254, 209, 25, 0.75);
}

/* Table Styles */
.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.tiptap-table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}

.tiptap-table-header,
.tiptap-table-cell {
  border: 2px solid #ced4da;
  box-sizing: border-box;
  min-width: 1em;
  padding: 3px 5px;
  position: relative;
  vertical-align: top;

  > * {
    margin-bottom: 0;
  }
}

.tiptap-table-header {
  background-color: #f1f3f5;
  font-weight: bold;
  line-height: 1;
  text-align: left;
}

.resizable-image-container {
  display: inline-block;
  position: relative;
}

.resize-handle {
  opacity: 0;
  position: absolute;
  right: -4px;
  bottom: -4px;
  width: 8px;
  height: 8px;
  background: #4a86e8;
  border-radius: 50%;
  cursor: se-resize;
}

.resizable-image-container:hover .resize-handle {
  opacity: 1;
}
