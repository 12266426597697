.outline-editor {
  font-size: 14px;
}

.outline-content > div {
  height: 100%;
}
/**
 * Why a pseudo-element instead of padding bottom? TipTap gets rid of it when it adds content. Since we don't want this
 * on the parent container (tiptap is the one that scrolls) we add it as a pseudo element after the content instead.
 */
.outline-content .ProseMirror::after {
  content: " ";
  display: block;
  position: relative;
  width: 100%;
  height: 30px;
}
.outline-content .ProseMirror-focused {
  outline: 0;
}

.outline-content .ProseMirror-noderangeselection *::selection {
  background: transparent;
}

.outline-content .ProseMirror-noderangeselection * {
  caret-color: transparent;
}

.outline-content .ProseMirror-selectednode,
.outline-content .ProseMirror-selectednoderange {
  position: relative;
  background-color: rgba(255, 255, 255, 0.6);
}

.outline-content .ProseMirror-selectednode::before,
.outline-content .ProseMirror-selectednoderange::before {
  position: absolute;
  pointer-events: none;
  z-index: -1;
  content: "";
  top: -0.25rem;
  left: -0.25rem;
  right: -0.25rem;
  bottom: -0.25rem;
  background-color: #70cff850;
  border-radius: 0.2rem;
}

.editor-content h1 {
  font-size: 1em;
}

.editor-content h2 {
  font-size: 0.93em;
}

.editor-content h3 {
  font-size: 0.93em;
}

/**
 * Tip tap behavior is odd for padding-top. When clicking on it the cursor gets placed at the beginning of the line
 * instead of the end or at the position currently highlighted. After trying several approaches, this seemed the most
 * reliable solution: to indicate with the cursor that clicking will place the cursor at the beginnng of the line.
 * 
 * Note that padding top is still required, otherwise the height becomes inconsistent while dragging and dropping.
 * Also note that the left offset is to avoid interferring with the drag and drop behavior.
 */
.outline-content h1:before,
.outline-content h2:before,
.outline-content h3:before {
  display: block;
  content: " ";
  position: absolute;
  height: 6px;
  top: 0;
  left: 50px;
  width: calc(100% - 50px);
  cursor: w-resize;
}

.outline-content h1,
.outline-content h2,
.outline-content h3 {
  position: relative;
  padding-top: 6px;
  padding-bottom: 6px;
  white-space: normal;
  word-wrap: break-word !important;
  overflow-wrap: break-word;
}
.outline-content h1 {
  margin-left: 24px;
  border-color: transparent;
}
.outline-content h2 {
  margin-left: 42px;
}
.outline-content h3 {
  margin-left: 52px;
}
.outline-content h1.active-line,
.outline-content h2.active-line,
.outline-content h3.active-line {
  border-color: black;
}

.custom-drag-handle::after {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  position: relative;
  left: 7px;
  height: 1.25rem;
  content: "⠿";
  font-weight: 700;
  cursor: grab;
  background: #0d0d0d10;
  color: #0d0d0d50;
  border-radius: 0.25rem;
}

/**
 * tippy doesn't offer a way to vertically center the popup. Since this varies per font size we manually adjust it here.
 * Note that multiline items are not accounted for using this approach so the tooltip will appear off-center there
 */
.outline-editor[data-font-size="12"] .custom-drag-handle::after {
  top: 4px;
}
.outline-editor[data-font-size="13"] .custom-drag-handle::after,
.outline-editor[data-font-size="14"] .custom-drag-handle::after,
.outline-editor[data-font-size="15"] .custom-drag-handle::after {
  top: 5px;
}
.outline-editor[data-font-size="16"] .custom-drag-handle::after {
  top: 6px;
}
.outline-editor[data-font-size="17"] .custom-drag-handle::after,
.outline-editor[data-font-size="18"] .custom-drag-handle::after {
  top: 7px;
}
