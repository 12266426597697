.placeholder {
  position: relative;
  display: flex;
  align-items: center;
  color: rgba(var(--color-foreground), 0.8);
  padding: 1.5em 1.5em;
  width: 100%;
  background: rgba(var(--color-foreground), 0.08);
  border: 1px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  font-size: 1em;
}

.placeholder:hover,
.placeholder:focus {
  outline: none;
  background: rgba(var(--color-foreground), 0.1);
}

.placeholder_icon {
  margin-right: 0.875rem;
  display: flex;
  align-items: center;
}

.popover_content {
  width: 280px;
  background: rgba(var(--color-surface-over), 0.92);
  backdrop-filter: blur(8px);
  box-shadow: var(--shadow-over);
  border-radius: 10px;
}

.popover_arrow {
  fill: rgba(var(--color-surface-over), 0.92);
}

.popover_form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  gap: 0.5rem;
}

.input_row {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 0.75em;
  color: rgba(var(--color-foreground), 0.8);
  margin-bottom: 0.125rem;
  display: block;
}

.input {
  display: block;
  flex-grow: 1;
  margin: 0;
  border: 0;
  font-size: 0.875em;
  background: transparent;
  height: 1.75rem;
  border: 1px solid rgba(var(--color-foreground), 0.16);
  border-radius: 6px;
  padding: 0 0.375rem;
}

.input::placeholder {
  color: rgba(var(--color-foreground), 0.4);
}

.input:focus {
  background: rgb(var(--color-surface));
  border-color: rgba(var(--color-primary), 0.6);
  outline: none;
  box-shadow: 0 0 0 3px rgba(var(--color-primary), var(--opacity-surface-selection));
}

.button {
  font-size: 0.875em;
  margin-top: 0.5rem;
  align-self: flex-end;
}
