.from-token-sidebar-surface-tertiary {
  --tw-gradient-from: var(--sidebar-surface-tertiary) var(--tw-gradient-from-position);
  /* --tw-gradient-to: hsla(0, 0%, 100%, 0) var(--tw-gradient-to-position); */
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.sidebarCollapsibleContent {
  width: 260px;
  visibility: visible;
}

.sidebarCollapsibleContent[data-state="open"] {
  animation: openSidebar 250ms;
  animation-fill-mode: forwards;
}
.sidebarCollapsibleContent[data-state="closed"] {
  animation: closeSidebar 250ms;
  animation-fill-mode: forwards;
}

@keyframes openSidebar {
  from {
    width: 0;
  }
  to {
    width: 260px;
  }
}

@keyframes closeSidebar {
  from {
    width: 260px;
  }
  to {
    width: 0;
    visibility: hidden;
  }
}
