@keyframes up {
  0% {
    opacity: 0;
    transform: translateY(var(--space-3));
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes down {
  0% {
    opacity: 0;
    transform: translateY(calc(-1 * var(--space-3)));
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.tooltip {
  z-index: var(--z-above);
  padding: var(--space-1) var(--space-3);
  border: 1px solid var(--color-tooltip-border);
  background: var(--color-tooltip);
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-md);
  color: var(--color-tooltip-text);
  font-size: var(--size-xs);
  font-weight: 500;
  animation: 0.15s ease-in-out;
}

.tooltip[data-side="top"] {
  animation-name: up;
}

.tooltip[data-side="bottom"] {
  animation-name: down;
}
