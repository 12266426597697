[contenteditable="true"]:empty:before,
[contenteditable="true"] > *:empty:before {
  content: attr(placeholder);
  display: block;
  color: #9ca3af;
}

.selected-ai::selection {
  color: rgba(var(--color-foreground), 1);
  background: rgba(var(--color-primary), var(--opacity-surface-selection));
}

.selected-ai mark {
  color: rgba(var(--color-foreground), 1);
  background: #e1d6fd;
  padding: 1.7px 0px;
}

.selected-ai * > *::selection {
  color: rgba(var(--color-foreground), 1);
  background: #e1d6fd;
}
