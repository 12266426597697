.avatar {
  display: block;
  position: relative;
  border: none;
  margin: 0;
  background: none;
  border-radius: 50%;
  padding: 0;
  box-shadow: 0 0 0 2px rgb(var(--color-surface));
}

.avatar img {
  border-radius: 50%;
}

.avatar_sm {
  height: 1.25rem;
  width: 1.25rem;
}

.avatar_md {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar_color {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 2px solid rgb(var(--color-surface));
}
