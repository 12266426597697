/* @import "./normalize.css"; */

:root {
  --color-black: 0 0 0;
  --color-white: 255 255 255;
  --color-gray-50: 250 250 250;
  --color-gray-100: 244 244 245;
  --color-gray-150: 236 236 238;
  --color-gray-200: 228 228 231;
  --color-gray-250: 220 220 224;
  --color-gray-300: 212 212 216;
  --color-gray-350: 187 187 193;
  --color-gray-400: 161 161 170;
  --color-gray-450: 137 137 146;
  --color-gray-500: 113 113 122;
  --color-gray-550: 98 98 107;
  --color-gray-600: 82 82 91;
  --color-gray-650: 73 73 81;
  --color-gray-700: 63 63 70;
  --color-gray-750: 51 51 56;
  --color-gray-800: 39 39 42;
  --color-gray-850: 32 32 35;
  --color-gray-900: 24 24 27;
  --color-gray-950: 18 18 21;
  --color-overlay: rgb(var(--color-black) / 60%);
  --space-0: 0;
  --space-1: 0.125rem;
  --space-2: 0.25rem;
  --space-3: 0.375rem;
  --space-4: 0.5rem;
  --space-5: 0.625rem;
  --space-6: 0.75rem;
  --space-7: 0.875rem;
  --space-8: 1rem;
  --space-9: 1.25rem;
  --space-10: 1.5rem;
  --space-11: 1.75rem;
  --space-12: 2rem;
  --space-13: 2.25rem;
  --space-14: 2.5rem;
  --space-15: 3rem;
  --space-16: 3.5rem;
  --space-17: 4rem;
  --space-18: 4.5rem;
  --space-19: 5rem;
  --space-20: 6rem;
  --space-21: 7rem;
  --space-22: 8rem;
  --space-23: 9rem;
  --space-24: 10rem;
  --size-2xs: 0.625rem;
  --size-xs: 0.75rem;
  --size-sm: 0.875rem;
  --size: 1rem;
  --size-lg: 1.125rem;
  --size-xl: 1.25rem;
  --size-2xl: 1.5rem;
  --size-3xl: 2rem;
  --size-4xl: 3rem;
  --radius-xs: 0.3rem;
  --radius-sm: 0.4rem;
  --radius: 0.7rem;
  --radius-lg: 0.8rem;
  --shadow-xs: 0 1px 4px rgb(var(--color-black) / 5%);
  --shadow-sm: 0 2px 8px rgb(var(--color-black) / 5%);
  --shadow: 0 3px 10px rgb(var(--color-black) / 5%);
  --shadow-lg: 0 4px 20px rgb(var(--color-black) / 5%);
  --shadow-xl: 0 5px 30px rgb(var(--color-black) / 5%);
  --backdrop-surface: saturate(2) blur(16px);
  --transition: 0.15s ease-in-out;
  --transition-linear: 0.15s linear;
  --header-height: 60px;
  --z-above: 100;
  --z-overlay: 200;
  --z-badge: 1000;
  --opacity-hover: 0.8;
  --opacity-disabled: 0.5;

  accent-color: var(--color-accent);
}

/* Light theme */
:root {
  --color-red: 239 67 67;
  --color-green: 132 204 22;
  --color-accent: rgb(var(--color-gray-900));
  --color-surface: rgb(var(--color-gray-100));
  --color-surface-hover: rgb(var(--color-gray-150));
  --color-surface-elevated: rgb(var(--color-white));
  --color-border: rgb(var(--color-gray-150));
  --color-border-contrasted: rgb(var(--color-gray-250));
  --color-border-transparent: rgb(var(--color-gray-900) / 10%);
  --color-skeleton: rgb(var(--color-gray-150));
  --color-skeleton-shine: rgb(var(--color-gray-50));
  --color-tooltip: rgb(var(--color-gray-950));
  --color-tooltip-text: rgb(var(--color-white));
  --color-tooltip-border: rgb(var(--color-gray-750));
  --color-text: rgb(var(--color-gray-900));
  --color-text-light: rgb(var(--color-gray-600));
  --color-text-lighter: rgb(var(--color-gray-500));
  --color-text-lightest: rgb(var(--color-gray-400));

  color-scheme: light;
}

/* Dark theme */
[data-theme="dark"] {
  --color-red: 248 113 113;
  --color-green: 162 230 53;
  --color-accent: rgb(var(--color-white));
  --color-surface: rgb(var(--color-gray-850));
  --color-surface-hover: rgb(var(--color-gray-800));
  --color-surface-elevated: rgb(var(--color-gray-950));
  --color-border: rgb(var(--color-gray-750));
  --color-border-contrasted: rgb(var(--color-gray-650));
  --color-border-transparent: rgb(var(--color-white) / 10%);
  --color-skeleton: rgb(var(--color-gray-750));
  --color-skeleton-shine: rgb(var(--color-gray-850));
  --color-text: rgb(var(--color-white));
  --color-text-light: rgb(var(--color-gray-300));
  --color-text-lighter: rgb(var(--color-gray-400));
  --color-text-lightest: rgb(var(--color-gray-500));

  color-scheme: dark;
}

html,
body {
  background: var(--color-surface-elevated);
  color: var(--color-text);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

html,
body,
#__next {
  max-width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

html.grabbing {
  cursor: grabbing;
}

html.grabbing * {
  user-select: none;
}
