.animatedGradient {
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: 9;
  background: linear-gradient(45deg, transparent, transparent, rgba(255, 255, 255, 0.3), transparent, transparent);
  background-size: 400% 400%;
  animation: gradientShift 6s infinite ease-in-out;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animatedDiv {
  position: absolute;
  width: 31px;
  height: 31px;
  background-color: black;
  animation: morphAndRotate9 8s infinite linear;
}
.animatedDiv2 {
  position: absolute;
  width: 31px;
  height: 31px;
  background-color: black;
  animation: morphAndRotate28 8s infinite linear;
}

@keyframes morphAndRotate9 {
  0% {
    transform: scale(1) rotate(0deg);
    border-radius: 50%;
  }
  6% {
    transform: scale(1) rotate(0deg);
    border-radius: 50%;
  }
  11% {
    transform: scale(1) rotate(0deg);
    border-radius: 50%;
  }
  35% {
    transform: scale(1) rotate(90deg);
    border-radius: 28%;
  }
  50% {
    transform: scale(1) rotate(180deg);
    border-radius: 28%;
  }
  85% {
    transform: scale(1) rotate(270deg);
    border-radius: 28%;
  }
  100% {
    transform: scale(1) rotate(360deg);
    border-radius: 50%;
  }
}

@keyframes morphAndRotate28 {
  0% {
    transform: scale(1) rotate(45deg);
    border-radius: 50%;
  }
  6% {
    transform: scale(1) rotate(45deg);
    border-radius: 50%;
  }
  11% {
    transform: scale(1) rotate(45deg);
    border-radius: 50%;
  }
  35% {
    transform: scale(1) rotate(135deg);
    border-radius: 28%;
  }
  50% {
    transform: scale(1) rotate(225deg);
    border-radius: 28%;
  }
  85% {
    transform: scale(1) rotate(315deg);
    border-radius: 28%;
  }
  100% {
    transform: scale(1) rotate(405deg);
    border-radius: 50%;
  }
}
